/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* don't show the svg icons in event cells   */
  .EventCell > svg {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 812px and up) */
@media only screen and (min-width: 812px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

html {
  min-width: fit-content;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  padding: 0;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.NavigateLink {
  padding: 0.05rem 0.3rem !important;
}

.nav-link {
  cursor: pointer;
  color: inherit !important;
}

a.nav-link {
  color:inherit;
}

.inlineLink {
  display: inline !important;
  padding: 0 !important;
  color: #0a64a6;
}

.inlineLink:hover {
  text-decoration: underline !important;
}

.tooltip {
  pointer-events: none;
}

.nav-link:hover {
  text-decoration: underline !important;
  color: #0a64a6 !important;
}

.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.CalendarTable {
  border: 1px solid #0a64a6;
  background-color: #a7d1ff;
}

.CalendarTableCell {
  border-left: 1px solid #0a64a6;
  border-top: 1px solid #0a64a6;
  flex: 1 1;
  align-items: flex-start;
}

.PersonName {
  border-left: 1px solid #0a64a6;
  font-weight: bold;
  font-size: medium;
  font-style: normal;
  text-align: center;
  flex: 1 1;
  overflow: hidden;
}

.DayOfWeekCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #0a64a6;
  width: 10%;
  text-align: center;
  line-height: 1;
}

.DayOfWeekCellToday {
  background-color: #59a9ff;
}

.CalendarTableCellToday {
  background-color: #59a9ff;
}

.DayOfMonth {
  font-size: 16pt;
  text-align: center;
}

.DayOfWeek {
  font-size: 10pt;
  text-align: center;
}

.DayOfWeekShort {
  display: none;
}

@media (max-width: 812px) {
  .DayOfWeekShort {
    display: inline-block;
  }
  .DayOfWeekLong {
    display: none;
  }
}

.EventCell {
  font-size: 9.5pt;
  padding: 0.15rem 0.5rem !important;
  display: flex !important;
  align-items: center;
}

.EventCell.nav-link {
  color:inherit;
}

.EventCellMorning {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.EventCellAfternoon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.EventCellEvening {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (max-width: 812px) {
  .EventCellEndTime {
    display: none;
  }
}

.CalendarHeaderTable {
  display: flex;
  border: 1px none #0a64a6;
  width: 100%;
  border-collapse: collapse;
  justify-content: space-between;
  background-color: #a7d1ff;
}

.NavigateWeekTableCell {
  text-transform: uppercase;
}

.NavigateMonthsTableCell {
  padding-right: 10px;
  padding-left: 10px;
  text-transform: uppercase;
}

.MonthName {
font-size: xx-large;
text-transform: uppercase;
border-collapse: collapse;
}

.MonthNameShort {
  display: none;
}

@media (max-width: 812px) {
  .MonthNameShort {
    display: inline-block;
  }
  .MonthNameLong {
    display: none;
  }
}

.ObservedHoliday {
  color: red;
  font-size: 10pt;
}

.Anniversary {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: red;
  font-size: 10pt;
  align-items: unset;
}

.CalendarButtonToolbar {
display: flex;
justify-content: center;
padding-top: 10px;
}

.CalendarSignedInName {
display: flex;
justify-content: center;
text-align: center;
color: #0a64a6;
font-size: small;
margin-top: 8px;
}

.CalendarFooter {
background-color: #FFFFFF;
width: 100%;
color: #0a64a6;
font-size: small;
margin-top: 8px;
display: flex;
flex-direction: column;
justify-content: center;
text-align: center;
}

.CalendarMenu {
display: flex;
justify-content: left;
}

.CalendarMenuItem {
color: red;
font-size: small;
}

.CalendarMenuItem.selected {
text-decoration-line: underline;
}

.ApptButtonAttendee {
}

.ApptAdditionalElements {
}

.ApptFormGroup {
}

.ApptFormLabel {
color: #0a64a6;
}

.ApptOptions {
}
